<script lang="ts" setup>
import { ref } from "vue";
import { STATES } from "@/constants/states";
import ItemList from "~/components/product/ItemList.vue";
import { useHelper } from "~/composables/helper";
import type Product from "~/types/Product";

// Properties
const props = defineProps({
    userState: String,
});

// Data
const products = ref([] as Product[]);

const limit = 8;
let filtersAndFields = [`fields=${useHelper().collectionProductFields}`];
if (props.userState) {
    filtersAndFields.push(`filter[states:contains]=${props.userState.toLowerCase()}`);
}
// sort by state_specific so that state-specific titles show up at the top of the list. Get featured products first.
let records = await useHelper().getCollection(
    "products",
    filtersAndFields.join("&"),
    limit,
    1,
    "state_specific,-featured",
);
if (records) {
    products.value = records as Product[];
}

// If there are less than 8 products, try to get product that was recently added
if (products.value.length < limit) {
    filtersAndFields = [`fields=${useHelper().collectionProductFields}`];

    if (products.value.length > 0) {
        const pids = products.value.map((product: any) => product.c_id);
        if (pids.length === 1) {
            filtersAndFields.push(`filter[id:not]=${pids[0]}`);
        } else if (pids.length > 1) {
            filtersAndFields.push(`filter[id:not_in]=${pids.join("|")}`);
        }
    }

    records = await useHelper().getCollection("products", filtersAndFields.join("&"), limit - products.value.length);
    if (records) {
        products.value = products.value.concat(records as Product[]);
    }
}
</script>

<template>
    <div v-if="products && products.length > 0">
        <HrWithText>
            <h2 class="text-2xl font-semibold">
                Featured {{ props.userState ? STATES[props.userState.toUpperCase()] : "" }} Books
            </h2>
        </HrWithText>

        <div class="mb-8 text-center">
            <ButtonSecondary
                type="link"
                :href="`/products/state/${props.userState ? props.userState : 'ga'}`"
                class="mt-4"
                >Shop All Books
            </ButtonSecondary>
        </div>
        <div class="mb-10 lg:flex">
            <!-- Main feature products -->
            <div class="relative hidden lg:block lg:w-1/3 lg:flex-none lg:pr-20">
                <NuxtLink :to="`/products/${products[0].c_slug}`">
                    <img
                        :src="products[0].photo_2xl"
                        :srcset="`${products[0].photo_2xl} 1x, ${products[0].photo_2xl_2x} 2x`"
                        width="363"
                        height="469"
                        alt="Feature Product Cover Image"
                        class="mb-3 border border-gray-300 shadow-md shadow-gray-400"
                    />
                    <label class="text-lg font-bold">{{ products[0].title }}</label>
                    <img src="/images/featured.png" alt="New Product" class="absolute -top-3 -left-[15px] w-32" />
                </NuxtLink>
            </div>
            <!-- List feature products -->
            <div class="grid-cols-2 gap-10 sm:grid md:grid-cols-3 lg:flex-1 lg:grid-cols-4">
                <ItemList
                    v-for="product in products"
                    :key="'product-' + product.c_id"
                    :product="product"
                    :show-price="false"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
