<script lang="ts" setup>
import { onMounted } from "vue";
import { useHelper } from "~/composables/helper"; // Properties

// Properties
const props = defineProps({
    userState: String,
});

// Data
let filters = "";
if (props.userState) {
    filters = `filter[state:is]=${props.userState.toLowerCase()}`;
}
const selectedFeatures = ref();
selectedFeatures.value = await useHelper().getCollection("selected_features", filters, 6, 1, "-date");
// Try again without user state
if (!selectedFeatures.value || selectedFeatures.value.length <= 0) {
    selectedFeatures.value = await useHelper().getCollection("selected_features", "", 6, 1, "-date");
}

// Functions
onMounted(async () => {
    // swiper element
    const swiperEl = document.querySelector("#resource-slides");
    const btnNext = document.querySelector("#resource-slides-btn-next");
    const btnPrev = document.querySelector("#resource-slides-btn-prev");

    if (!swiperEl) {
        console.log("swiperEl not found - selectedFeatures Count:", selectedFeatures.value.length);
        return;
    }

    const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 15,
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: btnNext,
            prevEl: btnPrev,
        },
        autoplay: {
            delay: 8000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
            1280: { slidesPerView: 3 },
        },
        on: {
            init() {
                // ...
            },
        },
    };

    if (swiperEl) {
        // now we need to assign all parameters to Swiper element
        Object.assign(swiperEl, swiperParams);

        // and now initialize it
        swiperEl?.initialize();
    }
});
</script>

<template>
    <div v-show="selectedFeatures && selectedFeatures.length > 0 && selectedFeatures[0].image[0]?.url">
        <hr class="mx-0 mt-6 mb-2 w-full border-gray-400 px-0" />
        <div class="relative overflow-hidden">
            <swiper-container
                id="resource-slides"
                init="false"
                class="swiper-container swiper-initialized swiper-horizontal md:-ml-[12%] md:w-[125%]"
            >
                <swiper-slide
                    v-for="feature of selectedFeatures"
                    :key="feature.id"
                    class="my-6 overflow-hidden rounded-2xl border border-gray-300 shadow-lg"
                >
                    <NuxtLink :to="useHelper().selectedFeatureLink(feature)">
                        <img :src="feature.image[0]?.url" class="w-full object-cover" />
                    </NuxtLink>
                </swiper-slide>
            </swiper-container>

            <div
                class="absolute top-0 right-0 z-10 hidden h-full w-24 items-center justify-center border-l-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="resource-slides-btn-next"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-right-regular.svg" class="w-5" />
                </div>
            </div>
            <div
                class="absolute top-0 left-0 z-10 hidden h-full w-24 items-center justify-center border-r-0 bg-white p-4 shadow-xl shadow-white md:flex"
            >
                <div
                    id="resource-slides-btn-prev"
                    class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                >
                    <img src="/images/fa/chevron-left-regular.svg" class="w-5" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
